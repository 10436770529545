<template>
    <!-- <el-sub-menu index="/workflow/">
        <template #title>
            <el-icon>
                <Monitor />
            </el-icon>
            <span>办公系统</span>
        </template>
        <el-menu-item index="/workflow/configer">
            <el-icon>
                <Wallet />
            </el-icon>
            <span>流程配置</span>
        </el-menu-item>
    </el-sub-menu> -->
    
    <template v-for="item in menuData" :key="item.id">
        <el-sub-menu v-if="item.children.length > '0'" :index="item.name">
            <template #title>
                <component :is="item.icon"/>
                <span>{{ item.name }}</span>
            </template>
            <MenuTree :menu-data="item.children" />
        </el-sub-menu>
        <el-menu-item :index="item.value" v-else>
            <component :is="item.icon" />
            <span>{{ item.name }}</span>
        </el-menu-item>
    </template>
</template>
<script>
export default {
    name: 'MenuTree',
    props: ['menuData']
}
</script>
<style lang="scss" >
.el-sub-menu svg{width: 15px; height:15px; margin-right: 5px;}

.el-menu-item:hover,
.el-menu-item.is-active {
  background-color: #1890ff !important;
}
</style>