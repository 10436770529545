import options from '../../package.json'
var util = {
  getCookie: function (Name) {
    var search = Name + "=";
    if (document.cookie.length > 0) {
      var offset = document.cookie.indexOf(search);
      if (offset != -1) {
        offset += search.length;
        var end = document.cookie.indexOf(";", offset);
        if (end == -1) end = document.cookie.length;
        return unescape(document.cookie.substring(offset, end));
      } else
        return "";
    }
  },
  setCookie: function (name, value) {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + 1000 * 3600 * 24); //过期时间 2分钟
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";path=/";
  },
  delCookie: function (name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = api.getCookie(name);
    if (cval != null) {
      document.cookie = name + "=" + cval + "; path=/;expires=" + exp.toGMTString();
    }
  },
  requestParams: function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },

  getRequest: function () {
    var url = location.search; // 获取url中"?"符后的字串
    var theRequest = new Object();
    if (url.indexOf('?') >= 0) {
      let str = url.replace('?', ''),
        strArr = str.split('&');
      strArr.forEach(function (l) {
        let key = l.split('=')[0],
          val = l.split('=')[1];
        theRequest[key] = val
      })
    }
    return theRequest;
  },
  decodeHTML: function (str) {
    if (!str) return '';
    return str.replace(/&lt;/g, '<').replace(/&amp;/g, "&").replace(/&gt;/g, ">").replace(/&quot;/g, "\"").replace(/&#39;/g, "'").replace(/&ldquo;/g, '“').replace(/&rdquo;/g, '”').replace(/&nbsp;/g, ' ')
  },
  filterHTML: function (msg) {
    var msg = msg.replace(/<\/?[^>]*>/g, ''); //去除HTML Tag
    msg = msg.replace(/[|]*\n/, '') //去除行尾空格
    msg = msg.replace(/&npsp;/ig, ''); //去掉npsp
    return msg;
  },
  urlAddParams: function (url, params) {
    var search = url.split('.html?').length > 1,
      link = '',
      result;
    for (var key in params) {
      var s = '&' + key + '=' + params[key];
      link += s
    }
    if (!!search) {
      result = url + link
    } else {
      result = url + '?' + link.slice(1)
    }
    return result
  },
  transParams: function (params) {
    let results = [];
    Object.keys(params).forEach(key => {
      let val = params[key];
      if (typeof val === 'undefined') {
        val = ''
      }
      results.push([key, encodeURIComponent(val)].join('='))
    });
    return results.join('&')
  },
  transPrice: function (num) {
    return (num / 100).toFixed(2) + '元'
  },
  checkPhone(tel) {
    var phoneReg = /^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/;
    return phoneReg.test(tel)
  },
  checkCaptcha(captcha) {
    var capReg = /^[0-9]{6}$/;
    return capReg.test(captcha)
  },

  fmtDate(time, fmt) {
    fmt = fmt || 'yyyy-MM-dd HH:mm:ss';
    if (!time) return ''
    else {
      const date = new Date(time)
      const o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        S: date.getMilliseconds()
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ?
              o[k] :
              ('00' + o[k]).substr(('' + o[k]).length)
          )
        }
      }

      return fmt
    }
  },
  getTime: function () {
    return new Date().getTime();
  },
  getCurrentDate(type) {
    if (type == 'today') {
      const stamp1 = new Date(new Date().setHours(0, 0, 0, 0)); //获取当天零点的时间
      const stamp2 = new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1); //获取当天23:59:59的时间
      return [stamp1, stamp2];
    } else if (type == 'month') {
      const currentDate = new Date();
      //const end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59);
      const end = new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1); //获取当天23:59:59的时间
      const start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      return [start, end];
    } else {
      const date = new Date();
      const year = date.getFullYear();
      let start = new Date(year, 0, 1);
      //let end = new Date(year + 1, 0, 0);
      const end = new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1); //获取当天23:59:59的时间
      return [start, end];
    }
  },
  getShortCuts() {
    return [
      {
        text: "今天",
        value: (() => {
          const stamp1 = new Date(new Date().setHours(0, 0, 0, 0)); //获取当天零点的时间
          const stamp2 = new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1); //获取当天23:59:59的时间
          return [stamp1, stamp2];
        }),
      },
      {
        text: "本周",
        value: (() => {
          const date = new Date();
          const year = date.getFullYear();
          const month = date.getMonth();
          const day = date.getDate();
          const week = date.getDay();
          let start = new Date(year, month, day - week + 1);
          let end = new Date(year, month, day - week + 7);
          return [start, end];
        })(),
      },
      {
        text: "上周",
        value: (() => {
          const date = new Date();
          const year = date.getFullYear();
          const month = date.getMonth();
          const day = date.getDate();
          const week = date.getDay();
          let start = new Date(year, month, day - week - 6);
          let end = new Date(year, month, day - week);
          return [start, end];
        })(),
      },
      {
        text: "本月",
        value: (() => {
          const currentDate = new Date();
          const end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59);
          //const end = new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1); //获取当天23:59:59的时间
          const start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          return [start, end];
        }),
      },
      {
        text: "上月",
        value: (() => {
          const currentDate = new Date();
          const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
          const end = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0, 23, 59, 59);
          const start = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
          return [start, end];
        })(),
      },
      {
        text: "本年",
        value: (() => {
          const date = new Date();
          const year = date.getFullYear();
          let start = new Date(year, 0, 1);
          let end = new Date(year + 1, 0, 0);
          return [start, end];
        }),
      },
      {
        text: "去年",
        value: (() => {
          const date = new Date();
          let start = new Date(date.getFullYear() - 1, 0, 1);
          let end = new Date(date.getFullYear(), 0, 0);
          return [start, end];
        })(),
      },
    ];
  },
  getAllDay(start, end) { //返回 日期的数组 如 ['2020-07-10','2020-07-11']
    debugger
    let startTime = this.getDate(start);
    let endTime = this.getDate(end);
    console.log('startTime', startTime, endTime)
    let dateArr = [];
    while ((endTime.getTime() - startTime.getTime()) > 0) {
      let year = startTime.getFullYear();
      let month = (startTime.getMonth() + 1).toString().length === 1 ? "0" + (parseInt(startTime.getMonth().toString(), 10) + 1) : (startTime.getMonth() + 1);
      let day = startTime.getDate().toString().length === 1 ? "0" + startTime.getDate() : startTime.getDate();
      console.log('endTime', year, month, day)
      dateArr.push(year + "-" + month + "-" + day);
      startTime.setDate(startTime.getDate() + 1);
    }
    console.log('dateArr', dateArr);
    return dateArr;
  },
  //传入 YYYY-MM , YYYY-MM  (2020-09)  (2020-12)  返回 YYYY-MM 数组
  getYearAndMonth(start, end) {
    let result = [];
    let starts = start.split('-');
    let ends = end.split('-');
    console.log(starts);
    let staYear = parseInt(starts[0]);
    let staMon = parseInt(starts[1]);
    let endYear = parseInt(ends[0]);
    let endMon = parseInt(ends[1]);
    while (staYear <= endYear) {
      if (staYear === endYear) {
        while (staMon <= endMon) {
          var str = staYear + '-'+(staMon >= 10 ? staMon : '0' + staMon);
          result.push(str);
          staMon++;
        }
        staYear++;
      } else {
        if (staMon > 12) {
          staMon = 1;
          staYear++;
        }
        let str = staYear + '-'+(staMon >= 10 ? staMon : '0' + staMon);
        result.push(str);
        staMon++;
      }
    }
    console.log(result);
    return result;
  },

  saveData(key, val) {
    window.sessionStorage.setItem(key, val)
  },
  getData(key) {
    return window.sessionStorage.getItem(key)
  },
  delData(key) {
    window.sessionStorage.removeItem(key)
  },
  // saveCache(key, val, timer) {
  //     window.localStorage.setItem(key + ":timer", new Date().getTime() + (timer||0) * 1000);
  //     window.localStorage.setItem(key, val)
  // },
  // getCache(key) {
  //     let secord = window.localStorage.getItem(key + ":timer");
  //     if (secord && secord > new Date().getTime())
  //         return window.localStorage.getItem(key);
  //     return null;
  // },
  // delCache(key) {
  //     window.localStorage.removeItem(key + ":timer", timer);
  //     window.localStorage.removeItem(key)
  // },
  isInWeChat() {
    var ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf('micromessenger') > -1;
  },
  isInIos: function () {
    var ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1;
  },
  h5CallIos(params) {
    // h5调用wkwebview
    window.webkit.messageHandlers.iOS.postMessage(params);
  },
  getFullPath() {
    let pathName = function () {
      var arr = window.location.pathname.split('/');
      return arr.splice(0, arr.length - 1).join('/') + '/'
    }();
    let origin = window.location.origin
    return origin + pathName
  },
  reloadUrl(url) {
    if (this.isInApp()) {
      window.gloud.reload(url);
    }
  },
  loadJs(src) {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = "text/javascript";
      script.src = src;
      document.body.appendChild(script);

      script.onload = () => {
        resolve();
      }
      script.onerror = () => {
        reject();
      }
    })
  },

  getLocalstorage(key) {
    let value = window.localStorage.getItem(key)
    value = (value === null || value === 'undefined' || value === undefined) ? '' : value
    return value
  },
  setLocalstorage(key, value) {
    if (key) {
      if (typeof value === 'object') {
        value = JSON.stringify(value)
      }
      window.localStorage.setItem(key, value)
    }
  },
  removeLocalstorage(key) {
    if (this.getLocalstorage(key)) {
      window.localStorage.removeItem(key)
    }
  },

  getVersion() {
    return options.version + (this.getLocalstorage('version') || '');
  },

  getPageCache(key) {
    let value = window.localStorage.getItem(location.pathname + '/' + key)
    value = value === null ? '' : value
    return value
  },
  setPageCache(key, value) {
    key = location.pathname + '/' + key;
    if (key) {  // && value
      if (typeof value === 'object' && value != null) {
        value = JSON.stringify(value)
      }
      window.localStorage.setItem(key, value || '')
    }
  },
  isBase64(str) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  },
  // public method for encoding
  base64Encode(input) {// private property
    var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    // private method for UTF-8 encoding
    var _utf8_encode = function (string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";
      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }

      }
      return utftext;
    }
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;
    input = _utf8_encode(input);
    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output = output +
        _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
        _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
    }
    return output;
  },
  base64Decode(input) {
    var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    // private method for UTF-8 encoding
    // private method for UTF-8 decoding
    var _utf8_decode = function (utftext) {
      var string = "";
      var i = 0;
      var c = 0; var c1 = 0; var c2 = 0;
      while (i < utftext.length) {
        c = utftext.charCodeAt(i);
        if (c < 128) {
          string += String.fromCharCode(c);
          i++;
        } else if ((c > 191) && (c < 224)) {
          c2 = utftext.charCodeAt(i + 1);
          string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
          i += 2;
        } else {
          c2 = utftext.charCodeAt(i + 1);
          c3 = utftext.charCodeAt(i + 2);
          string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
          i += 3;
        }
      }
      return string;
    }
    var output = "";
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    while (i < input.length) {
      enc1 = _keyStr.indexOf(input.charAt(i++));
      enc2 = _keyStr.indexOf(input.charAt(i++));
      enc3 = _keyStr.indexOf(input.charAt(i++));
      enc4 = _keyStr.indexOf(input.charAt(i++));
      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;
      output = output + String.fromCharCode(chr1);
      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }
    output = _utf8_decode(output);
    return output;
  },
  toBigMoney(n) {
    /** 数字金额大写转换(可以处理整数,小数,负数) */
    var fraction = ['角', '分'];
    var digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    var unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
    var head = n < 0 ? '欠' : '';
    n = Math.abs(n);

    var s = '';

    for (var i = 0; i < fraction.length; i++) {
      s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
    }
    s = s || '整';
    n = Math.floor(n);

    for (var i = 0; i < unit[0].length && n > 0; i++) {
      var p = '';
      for (var j = 0; j < unit[1].length && n > 0; j++) {
        p = digit[n % 10] + unit[1][j] + p;
        n = Math.floor(n / 10);
      }
      s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
    }
    return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
  },
  fmtMoney(value) {
    if (isNaN(value) || (value || '').length == 0) return value;
    let absValue = Math.abs((value).toFixed(2));
    const newValue = ['', '', '']
    let fr = 1000
    let num = 3
    let text1 = ''
    let fm = 1
    while (absValue / fr >= 1) {
      fr *= 10
      num += 1
    }
    if (num <= 4) { // 千
      newValue[0] = absValue
    } else if (num <= 8) { // 万
      text1 = parseInt(num - 4) / 3 > 1 ? '千万' : '万'
      // tslint:disable-next-line:no-shadowed-variable
      fm = text1 === '万' ? 10000 : 10000000
      if (absValue % fm === 0) {
        newValue[0] = parseInt(absValue / fm) + ''
      } else {
        newValue[0] = parseFloat(absValue / fm).toFixed(2) + ''
      }
      newValue[1] = text1
    } else if (num <= 16) { // 亿
      text1 = (num - 8) / 3 > 1 ? '千亿' : '亿'
      text1 = (num - 8) / 4 > 1 ? '万亿' : text1
      text1 = (num - 8) / 7 > 1 ? '千万亿' : text1
      // tslint:disable-next-line:no-shadowed-variable
      fm = 1
      if (text1 === '亿') {
        fm = 100000000
      } else if (text1 === '千亿') {
        fm = 100000000000
      } else if (text1 === '万亿') {
        fm = 1000000000000
      } else if (text1 === '千万亿') {
        fm = 1000000000000000
      }
      if (absValue % fm === 0) {
        newValue[0] = parseInt(absValue / fm) + ''
      } else {
        newValue[0] = parseFloat(absValue / fm).toFixed(2) + ''
      }
      newValue[1] = text1
    }
    if (absValue < 1000) {
      newValue[0] = absValue + ''
      newValue[1] = ''
    }
    return (value < 0 ? '-' : '') + newValue.join('')
  },

  // validator:{
  //   notNull:[
  //     { required: true, message: '请输入完整内容', trigger: 'blur' }
  //   ],
  //   money:[
  //     { required: true, message: '请输入对应金额', trigger: 'blur' },
  //     {
  //       pattern: /^(([1-9]{1}\d{0,9})|(0{1}))(\.\d{1,2})?$/,
  //       message: "请输入合法的金额数字，最多两位小数",
  //       trigger: "blur"
  //     }
  //   ],
  // }
}

export default util;