/**
 * 基于 axios 请求模块
 */
import axios from 'axios'
import { ElMessage, ElLoading } from "element-plus/lib/components"
import router from '../router'
//import options from '../../package.json'
import util from '@/utils/util'

const request = axios.create({
    baseURL: process.env.VUE_APP_BASEURL, //process.env.BASE_API, // api的base_url
    //withCredentials: true,   // Access-Control-Allow-Origin为*则前端不能携带cookie
    async: true,
    timeout: 240000, //4m
})

// 添加请求拦截器
request.interceptors.request.use(config => {
    // 如果需要缓存--考虑到并不是所有接口都需要缓存的情况
    if (config.cache) {
        let source = axios.CancelToken.source()
        config.cancelToken = source.token;

        //console.log(options.version)

        let requestKey = generateReqKey(config); // 生成请求Key
        let data = cache.get(requestKey);
        // 判断缓存池中是否存在已有数据 存在的话 再判断是否过期
        // 未过期 source.cancel会取消当前的请求 并将内容返回到拦截器的err中
        if (data) {
            source.cancel(data)
        }
    }

    if (!config.cache) {
        // let queryDialogOverlay = document.querySelector(".load-dialog .el-overlay");
        // if (queryDialogOverlay && queryDialogOverlay.style.display != "none")
        //     loadTarget = ".load-dialog .el-dialog";
        if (config.loading == undefined || config.loading) {
            showFullScreenLoading()
        }
    }

    let access_token = sessionStorage.getItem('access_token') || '';
    let token_type = sessionStorage.getItem('token_type') || '';
    // 在发送请求之前设置token
    config.headers['Authorization'] = token_type + " " + access_token;
    return config;
}, error => {
    return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(response => {
    tryHideFullScreenLoading();

    const res = response.data;
    // 只缓存get请求
    if (response.config.method === 'get' && response.config.cache) {
        // 缓存数据 并将当前时间存入 方便之后判断是否过期
        cache.set(generateReqKey(response.config), res, response.config.cache);
    }

    // 如果返回的状态不是200 就主动报错
    if (res.code && res.code != 200) {
        ElMessage.warning(res.content || '接口错误');
        return Promise.reject(res || 'error')
    }
    return response.data;
}, error => {
    if (error.response == undefined && error.code == undefined && (typeof error.message == 'object')) {
        return error.message;
    } else if (error.code === 'ECONNABORTED') {
        ElMessage.warning('连接超时');
    } else if (error.response && error.response.status) {
        switch (error.response.status) {
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。                
            case 401:
                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                sessionStorage.clear();
                localStorage.clear();
            case 403:
                ElMessage.warning('登录过期，请重新登录');
                // 清除token
                //sessionStorage.removeItem('access_token');
                //sessionStorage.removeItem('token_type');

                //store.commit('loginSuccess', null);
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
                //console.log('/login?redirectUrl=' + encodeURIComponent(location.pathname + location.search));
                router.replace({
                    path: '/login',
                    query: {
                        redirectUrl: (location.pathname + location.search)
                    }
                });

                break;
            // 404请求不存在
            case 404:
                ElMessage.warning('网络请求不存在');
                break;
            // 其他错误，直接抛出错误提示
            default:
                ElMessage.error(error.response.data.content || '未知错误');
                break;
        }

        tryHideFullScreenLoading();

        return Promise.reject(error.response.data); // 返回接口返回的错误信息
    } else {
        tryHideFullScreenLoading();

        ElMessage.warning('出错啦~');
        console.error(error)
    }
});

function generateReqKey(config) {
    const {
        method,
        url,
        params,
        data
    } = config;
    return [method, url, JSON.stringify(params), JSON.stringify(data), util.getVersion()].join("&");
}

const cache = {
    set(key, val, timer) {
        window.localStorage.setItem(key + ":timer", new Date().getTime() + (timer || 0) * 1000);
        window.localStorage.setItem(key, JSON.stringify(val))
    },
    get(key) {
        let secord = window.localStorage.getItem(key + ":timer");
        if (secord && secord > new Date().getTime())
            return JSON.parse(window.localStorage.getItem(key) || '{}');
        return null;
    },
    del(key) {
        window.localStorage.removeItem(key + ":timer", timer);
        window.localStorage.removeItem(key)
    },
};



//计数器
let needLoadingRequestCount = 0

export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}

let loading
function startLoading() {
    loading = ElLoading.service({
        lock: true,
        text: '正在加载中…',
    })
}

function endLoading() {
    loading.close();
    document.getElementsByTagName('body')[0].className = '';
}

export default request


//需要使用的时候
// import request from 'request.js'
// request.xxx