import { createStore } from 'vuex'

export default createStore({
  state: {
    //token: '' //初始化token
    userInfo: {},
    webSetting: {}
  },
  mutations: {
    // //存储token方法
    // //设置token等于外部传递进来的值
    // setToken(state, token) {
    //   state.token = token
    //   sessionStorage.token = token //同步存储token至localStorage
    // },
    // delToken(state) {
    //   state.token = "";
    //   sessionStorage.remove("token");
    // }
    setUserInfo(state, data) {
      state.userInfo = data;
      sessionStorage.userInfo = JSON.stringify(data)
      //console.log(state.userInfo)
    },
    setWebSetting(state, data) {
      state.webSetting = data;
      sessionStorage.webSetting = JSON.stringify(data)
      //console.log(state.webSetting)
    }
  },
  getters: {
    // //获取token方法
    // //判断是否有token,如果没有重新赋值，返回给state的token
    // getToken(state) {
    //   if (!state.token) {
    //     state.token = sessionStorage.getItem('token')
    //   }
    //   return state.token
    // }
    getUserInfo: state => state.userInfo,
    getWebSetting: state => state.webSetting,
  },
  actions: {
  },
  modules: {}
})