<template>
  <el-dialog title="批量导入数据" width="80%" v-model="visible" top="10vh" destroy-on-close :before-close="changeEvent">
    <div class="load-dialog">
      <el-upload ref="upload" action :show-file-list="false" :multiple="false" :auto-upload="false" :on-change="uploader"
        style="display:inline-flex;vertical-align:middle; margin-right: 15px;">
        <template #trigger>
          <el-button plain type="primary" icon="upload">上传表格</el-button>
        </template>
      </el-upload>

      <el-button plain type="warning" icon="PriceTag" @click="template">模板下载</el-button>

      <div style="float: right;">
        <el-button type="primary" @click="submitEvent(rowList)"> 确定导入 </el-button>
        <el-button @click="visible = false; changeEvent()">取 消</el-button>
      </div>
    </div>

    <el-table class="table" :data="rowList" highlight-current-row border size="small"
      style="margin-top: 12px; min-height: 300px;">
      <template v-for="(col, index) in columns" :key="index" :prop="col.prop" :label="col.label">
        <el-table-column v-if="(col.type || '') === 'index' || col.label == '序号'" :label="col.label" width="50"
          align="center">
          <template #default="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 
        <el-table-column :prop="col.prop" :label="col.label" :sortable="col.sortable" :width="col.width || 0"
          :align="col.align || 'left'" :show-overflow-tooltip="col.showOverflowTooltip || false">
        </el-table-column> -->

        <el-table-column v-else :label="col.label" :width="col.width || 0" :align="col.align || 'left'"
          :show-overflow-tooltip="col.showOverflowTooltip || false">
          <template #default="scope">
            <el-text size="small" @click="editRow(scope.row)">
              {{ scope.row[col.prop] }}
            </el-text>
          </template>
        </el-table-column>

      </template>
    </el-table>

    <el-dialog v-model="rowDialog" width="65%" title="编辑数据行" append-to-body top="5vh">
      <el-form :model="rowData" v-for="(col, index) in columns" :key="index" :prop="col.prop" :label="col.label">
        <el-form-item v-if="(col.type || '') != 'index'" :label="col.label" :label-width="180">
          <el-input v-model="rowData[col.prop]" autocomplete="off"
            :style="'width:' + (col.width * 3) + 'px; max-width:100%; min-width:100px;'" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="rowDialog = false">取 消</el-button>
          <el-button type="primary" @click="rowDialog = false"> 确 定 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submit()"> 确 定 </el-button>
      </span>
    </template> -->
  </el-dialog>
</template>
<script>
export default {
  name: "Importer",
  props: {
    visible: { type: Boolean, default: false },
    columns: { type: Object, required: true }
  },
  emits: ["change", "submit"],

  data() {
    return {
      rowDialog: false,
      rowList: [],
      rowData: {},
    };
  },
  methods: {
    submitEvent: function () {
      if (this.rowList.length == 0) {
        this.$message({ message: '请上传模板表格文件', type: 'warning' });
        return;
      }

      this.$emit('submit', this.rowList)
    },

    uploader(file) {
      var formData = new FormData();
      formData.append(file.name, file.raw);
      formData.append("fileType", 'excel');
      formData.append("dataType", this.$options.name);
      formData.append("columns", JSON.stringify(this.columns));

      this.$kaung.transfer(formData)
        .then((res) => {
          console.log(res);
          this.$message({ message: res.content, type: res.type });
          if (res.code === 200) {
            this.rowList = res.data;
          }
        });
    },

    editRow(rowData) {
      this.rowDialog = true;
      this.rowData = rowData;
    },
    changeEvent: function () {
      //this.rowList = [];
      this.$emit('change', false);
    },
    template: function () {
      this.$kaung.template(this.columns)
        .then((res) => {
          this.$notify({
            title: (res.code == 200 ? "生成成功" : "生成失败"),
            message: res.content,
            type: res.type,
            duration: 10000
          });
          window.location.href = process.env.VUE_APP_BASEURL + res.data;
        });
    }
  },
  mounted() {
  },
};
</script>
<style lang="less"></style>