
import { ElMessage } from "element-plus/lib/components"
import router from '@/router/index.js'

export function login(params) {
  // return request({
  //   url: '/connect/token',
  //   method: 'post',
  //   // 跨域报错 可能出现在data上，测试的时候可以先不带data
  //   // data的处理方式需要放在request.js使用qs处理
  //   data:data,
  //   transformRequest: [
  //     function (data) {
  //        let ret = ''
  //        for (let it in data) {
  //           ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
  //        }
  //        ret = ret.substring(0, ret.lastIndexOf('&'));
  //        return ret
  //     }
  //   ],
  //   headers: {
  //      'Content-Type': 'application/x-www-form-urlencoded'
  //   }
  // });

  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();

    var method = "POST";
    var url = process.env.VUE_APP_BASEURL + "connect/token";
    var async = true;
    xhr.open(method, url, async);
    xhr.timeout = 1000 * 5; //设置xhr请求的超时时间
    xhr.setRequestHeader(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=utf-8'
    );

    let rets = ''
    for (let it in params) {
      rets += encodeURIComponent(it) + '=' + encodeURIComponent(params[it]) + '&'
    }
    rets = rets.substring(0, rets.lastIndexOf('&'));

    xhr.send(rets);
    xhr.onload = function () {
      let data = {};
      if ((xhr.responseText || '').length > 1) data = JSON.parse(xhr.responseText);

      if (xhr.readyState == 4 && xhr.status == 200) {
        sessionStorage.clear();
        sessionStorage.setItem("access_token", data.access_token);
        sessionStorage.setItem("token_type", data.token_type);

        resolve(data);
      } else {
        reject(data);
      }
    };

    // 请求超时方法
    xhr.ontimeout = function (e) {
      console.log('接口请求超时');
    };

    // 请求错误方法
    xhr.onerror = function (e) {
      console.log('接口请求失败');
    };
  });


  // var url = process.env.VUE_APP_BASEURL + "connect/token";
  // var xhr = new XMLHttpRequest();
  // xhr.open("POST", url);

  // xhr.onload = function () {
  //     console.log(xhr.status, JSON.parse(xhr.responseText));
  //     return JSON.parse(xhr.responseText);
  // };
  // //xhr.setRequestHeader("Authorization", "Bearer " + "");
  // xhr.send(JSON.stringify(params));
}

export function getInfo(token) {
  // return request({
  //     url: '/user/info',
  //     method: 'get',
  //     params: {
  //         token
  //     }
  // })
}

export function logout() {
  sessionStorage.clear();
  localStorage.clear();
  //this.$message.success("您已成功退出~");
  ElMessage.warning('您已成功退出~');
  router.push("/login");
}