<template>
  <el-scrollbar view-style="min-height:100%">
    <div class="logo-container">
      <router-link to="/">
        <el-icon class="logo">
          <Opportunity />
        </el-icon>
        <span class="title" v-if="!isCollapse"> K-ERP </span>
      </router-link>
    </div>
    
    <el-menu :default-active="$route.path" class="aside-menu" :default-openeds="openeds" :collapse="isCollapse"
      background-color="#282c34" text-color="#fff" active-text-color="#fff" router>
      <menuTree :menu-data="menus" />
    </el-menu>
  </el-scrollbar>
</template>


<script>
import menuTree from '@/layout/components/menuTree'
import { currentMenus } from "@/api/sys";
export default {
  name: "Aside",
  components: {
    menuTree
  },
  props: ["is-collapse"],
  data() {
    return {
      openeds: ["index"],
      env: "",
      menus: []
    };
  },

  methods: {
    fetctMenuData() {
      var that = this;
      //currentMenus 个人权限菜单, fetctMenus 所有菜单
      currentMenus().then((res) => {
        that.menus = res;
      });
    },
  },
  mounted() {
    console.log(JSON.stringify(process.env));
    this.env = process.env.NODE_ENV;
    this.fetctMenuData();
  },

};
</script>

<style scoped lang="less">
.aside-menu:not(.el-menu--collapse) {
  width: 200px;
  min-height: 100vh;
}


.logo-container {
  border-right: solid 1px #e6e6e6;
  height: 65px;
  overflow: hidden;
  line-height: 65px;
  background: #282c34;
  text-align: center;

  .logo,
  .title {
    font-size: 32px;
    color: #fff;
    text-decoration: none;
  }
}
</style>