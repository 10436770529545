<template>
  <el-container class="main-container">
    <el-aside class="aside sidebar" width="auto" v-show="!isCollapse">
      <app-aside :is-collapse="isCollapse" />
    </el-aside>
    <el-container>
      <el-header>
        <el-row class="header">
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" @click="isCollapse = !isCollapse">
            <el-icon>
              <Expand v-if="isCollapse" />
              <Fold v-if="!isCollapse" />
            </el-icon>
            <span> {{ $store.getters.getWebSetting.projectName }} </span>
          </el-col>
          <el-col :xs="24" :sm="12" :md="18" :lg="18" :xl="18" class="right-panel">
            <el-text @click="fullscreenEnabled">
              <el-icon>
                <FullScreen />
              </el-icon>
            </el-text>
            <!-- <el-text>测试 </el-text> -->
            <el-dropdown v-if="sidemenus && sidemenus.length > 0">
              <el-text>
                <el-icon>
                  <Platform />
                </el-icon>
              </el-text>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="item in sidemenus">
                    <a :href="item.value" target="_blank">{{ item.name }}</a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-text> | </el-text>
            <el-dropdown>
              <el-text>
                {{ $store.getters.getUserInfo.userName || '更多设置' }}
                <el-icon>
                  <ArrowDown />
                </el-icon>
              </el-text>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="userSetting()">个人信息</el-dropdown-item>
                  <el-dropdown-item @click="clearEvent()">清理缓存</el-dropdown-item>
                  <el-dropdown-item @click="resetEvent()">重置密码</el-dropdown-item>
                  <el-dropdown-item divided @click="logoutEvent()">
                    退 出
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <!-- <el-footer>Footer</el-footer> -->
    </el-container>
  </el-container>

  <div class="dialog">
    <resetPwdDialog :visible="resetDialogVisible"></resetPwdDialog>
  </div>


  <static-dialog ref="userDialog" class="dialog" title="个人设置" :okVisible="false">
    <el-form :model="forms" ref="forms" label-width="90px">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="公司" prop="companyName">
            <el-input v-model="forms.companyName" autocomplete="off" disabled> </el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="部门" prop="departmentName">
            <el-input v-model="forms.departmentName" autocomplete="off" disabled> </el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="账户" prop="account">
            <el-input v-model="forms.account" autocomplete="off" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="姓名" prop="realName">
            <el-input v-model="forms.realName" autocomplete="off" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="性别" prop="gender" class="gender" disabled>
            <el-radio v-model="forms.gender" label="男">男</el-radio>
            <el-radio v-model="forms.gender" label="女">女</el-radio>
            <el-radio v-model="forms.gender" label="无">无</el-radio>
            <!-- <el-radio-group v-model="forms.gender" disabled>
              <el-radio label="男" />
              <el-radio label="女" />
              <el-radio label="无" />
            </el-radio-group>-->
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="手机" prop="mobile">
            <el-input v-model="forms.mobile" autocomplete="off" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="生日" prop="birthday">
            <el-date-picker v-model="forms.birthday" type="date" placeholder="选择生日" format="YYYY-MM-DD"
              :editable="false" disabled>
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </static-dialog>
</template>

<script>
import store from "@/store";
import StaticDialog from "@/components/static-dialog.vue";
import AppAside from "./components/aside";
import resetPwdDialog from "@/views/sys/user/reset.vue";

import { currentMenus, webSetting, currentUser } from "@/api/sys";
import { logout } from "@/api/login";

import 'element-plus/theme-chalk/display.css'

export default {
  name: "LayoutIndex",
  components: {
    AppAside, StaticDialog, resetPwdDialog
  },
  props: {},
  data() {
    return {
      fullscreen: false,
      isCollapse: false,

      sidemenus: null,
      forms: {},
      rules: {
        oldpassword: [
          { min: 0, max: 30, message: "请输入账号原密码", trigger: "blur", },
        ],
        newpassword: [
          { min: 4, max: 30, message: "密码长度不符合要求", trigger: "blur", },
        ],
        confirmpassword: [
          { min: 4, max: 30, message: "密码长度不符合要求", trigger: "blur", },
        ],
      },
      resetDialogVisible: false
    };
  },
  methods: {
    // openPwd() {
    //   this.$refs.dialog.toggle();
    // },
    // resetPwd() {
    //   let that = this;
    //   this.$refs.forms.validate((valid) => {
    //     if (!valid) return;

    //     if (!!!that.forms.newpassword) {
    //       that.$message.error("请输入新旧密码~");
    //       return;
    //     }

    //     if (that.forms.newpassword != that.forms.confirmpassword) {
    //       that.$message.error("两次输入新密码不一致~");
    //       return;
    //     }

    //     resetUserPwd(this.forms).then((res) => {
    //       if (res.code === 200) {
    //         that.$message({ type: "success", message: res.content, });
    //         setTimeout(function () {
    //           that.logout();
    //         }, 1000);
    //       }
    //     });
    //   });
    // },

    fullscreenEnabled() {
      if (!this.fullscreen) {
        //启动全屏
        const element = document.documentElement;
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
        this.fullscreen = true;
      } else {
        //退出全屏
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
        this.fullscreen = false;
      }
    },

    setAppWidth() {
      var val = document.body.clientWidth
      const def = 800 // 默认宽度
      if (val < def) {
        this.isCollapse = true
      } else {
        this.isCollapse = false
      }
    },
    resetEvent() {
      this.resetDialogVisible = true;
    },
    logoutEvent() {
      logout();
    },
    clearEvent() {
      this.$confirm("清理缓存将导致页面填写内容丢失，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$util.setLocalstorage('version', Math.floor(Math.random() * Math.pow(10, 4)));
          setTimeout(() => {
            location.reload();
          }, 500);
        });
    },

    userSetting() {
      this.$refs.userDialog.toggle();//打开
      this.forms = this.$store.getters.getUserInfo;
    },
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setAppWidth()
      })()
    }
  },
  beforeCreate() {
    currentUser().then((res) => {
      store.commit("setUserInfo", res);
      //console.log(that.$store.getters.getUserInfo);
    });
    webSetting().then((res) => {
      store.commit("setWebSetting", res);
      //console.log(res);
    });

    currentMenus('侧边栏').then((res) => {
      this.sidemenus = res;
    });
  },
  created() {
    this.setAppWidth()
  }
};
</script>
<style scoped lang="less">
.main-container {
  width: 100%;
  height: 100%;
}

.header {
  height: 60px;
  justify-content: space-between;
  align-items: center;
}

// cursor: pointer;
.right-panel {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;

  .el-text {
    line-height: 50px;
    margin: 0 7px;
    cursor: pointer;

    .el-icon {
      vertical-align: 0;
    }
  }

}

.el-main {
  background-color: #f6f8f9;
  color: #515a6e;
}

@media screen and (max-width: 960px) {
  .el-main {
    padding: 0;
  }

  .right-panel {
    display: none;
  }
}


.header .el-icon {
  top: 4px;
  position: relative;
  font-size: 1.2em;
}
</style>