<template>
  <div class="static-dialog load-dialog">
    <!-- <el-button link @click="visible = true">点击打开 Dialog</el-button>
    <span>{{ visible }}</span> -->
    <el-dialog :title="title" :width="width" v-model="visible" @close="close" @opened="opened" :fullscreen="fullscreen" destroy-on-close>
      <slot></slot>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visible = false" size="large">取 消</el-button>
          <el-button v-if="okVisible" type="primary" @click="ok()" size="large"> 确 定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "StaticDialog",
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "800px",
    },
    // data: {
    //   type: Object,
    //   default: {},
    // },
    // visible: {
    //   type: Boolean,
    //   default: false,
    // },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      default: function () { },
    },
    opened: {
      type: Function,
      default: function () { },
    },
    ok: {
      type: Function,
      default: function () { },
    },
    okVisible: {
       type: Boolean,
       default: true,
     },
  },
  data: function () {
    return {
      visible: false,
    };
  },
  methods: {
    toggle: function () {
      this.visible = !this.visible;
    },
  },
  // computed: {
  //   display: function () {
  //     return this.visible;
  //   },
  // },
  // watch: {
  //   visible: function (newvisible, oldvisible) {
  //     this.display=newvisible;
  //   },
  // },
};
</script>

<style scoped>
</style>