import { createApp } from 'vue'
import ElementPlus from 'element-plus'

import './styles/global.css'
// import 'element-plus/lib/theme-chalk/display.css'
import 'element-plus/dist/index.css'
import 'element-plus/lib/style.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import App from './App.vue'
import router from './router'
import store from './store'
import util from './utils/util.js'
import kaung from '@/utils/kaung.js'

//import print from 'print-js'

import signalr from './utils/signalr'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus, { locale: zhCn, zIndex: 3000 })
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(signalr)


import Importer from "./components/importer.vue";
app.component("importer", Importer);



app.mount('#app')
//工具类封装成插件
app.config.globalProperties.$util = util
app.config.globalProperties.$kaung = kaung

// import wx from "weixin-js-sdk";
// app.config.globalProperties.$wxsdk = wx


