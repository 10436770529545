import request from '@/utils/request'
import util from '@/utils/util'

export function webSetting() {
  return request({
    url: '/WebSetting?v=' + util.getVersion(),
    method: 'get',
    cache: 60 * 60 * 24 * 7 //7天的缓存
  });
}

export function currentUser() {
  return request({
    //url: '/connect/userinfo',
    url: '/BaseUser/Current',
    method: 'get',
    cache: 60 * 60
  }).then((res) => {
    return res.data;
  });;
}

export function currentMenus(category) {
  return request({
    url: '/BaseModule/UserTreeData?category=' + (category || '') + '&v=' + util.getVersion(),
    params: {},
    method: 'get'
  }).then((res) => {
    return res.data;
  });
}



export function fetchUsers(params) {
  return request({
    url: '/BaseUser/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}


export function delUsers(id) {
  return request({
    url: '/BaseUser/' + id,
    method: 'delete'
  });
}


export function submitUser(data) {
  return request({
    url: '/BaseUser/' + (data.id || ''),
    method: data.id ? 'put' : 'post',
    data: data
  });
}



export function resetUserPwd(data) {
  return request({
    url: '/BaseUser/reset',
    method: 'get',
    params: data
  });
}




export function fetchRoles(params) {
  return request({
    url: '/BaseRole/Search',
    method: 'post',
    data: params
  });
}


export function delRoles(id) {
  return request({
    url: '/BaseRole/' + id,
    method: 'delete'
  });
}


export function submitRole(data) {
  return request({
    url: '/BaseRole/' + (data.id || ''),
    method: data.id ? 'put' : 'post',
    data: data
  });
}

export function submitRelationByGlobalId(arr, category, globalId) {
  return request({
    url: '/BaseRelation/submitRelationByGlobalId/' + (category || '') + '/' + globalId,
    method: 'post',
    data: arr
  });
}


export function submitRelationByObjectId(arr, category, objectID) {
  return request({
    url: '/BaseRelation/submitRelationByObjectId/' + (category || '') + '/' + objectID,
    method: 'post',
    data: arr
  });
}


export function fetchRelation(category, globalId) {
  return request({
    url: '/BaseRelation/GetKeys/' + (category || '') + '/' + globalId,
    method: 'get'
  });
}


export function fetchApiLogs(params) {
  return request({
    url: '/SysApiLog/Search',
    method: 'post',
    data: params
  });
}



